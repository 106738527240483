import {Component, Provide, Vue} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import {GetBySinglePageContentListReq} from "@/interface/res/common";
import {GetBySinglePageContentListApi} from "@/network/modules/common";


@Component({
  components: {
    common
  },
  computed: {
  }
})
export default class Privacy extends Vue {
  @Provide() loading:boolean = false
  @Provide() data:string = ""

  created(){
    const _this:any = this;
    _this.GetBySinglePageContentListFn()
  }

  /**
   * @Author HS
   * @Date 2021/7/9 5:20 下午
   * @Description: 隐私保护
   * @Params: null
   * @Return: null
  */
  async GetBySinglePageContentListFn(){
    const _this:any = this;
    _this.loading = true
    let params:GetBySinglePageContentListReq = {
      type:"0"
    }
    const res = await GetBySinglePageContentListApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.data = data[0].content
  }
}
